import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Modal, Row } from "react-bootstrap";
import sendSlackMessage from "utils/helpers/sendSlackMessage";
import MobileNoInput from "views/auth/components/MobileNoInput";
import DefaultButton from "views/components/button/DefaultButton";
import { getLocalStorageUser } from "utils/helpers/localstorage";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { POST_CONTACTFORM } from "redux-store/sagas/saga-actions";
import ENV from "utils/helpers/env";

const ContactusForm = ({ fromApp = false }) => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();

  const lsuser = getLocalStorageUser();

  const user = {
    name: lsuser?.name || params.get("name"),
    lastName: lsuser?.lastName || params.get("lastName"),
    email: lsuser?.email || params.get("email"),
    phoneNumber:
      lsuser?.phoneNumber ||
      params.get("phoneNumber") ||
      lsuser?.secondaryPhone,
    countryCode:
      lsuser?.countryCode ||
      (params.has("countryCode")
        ? "+" + params.get("countryCode")
        : lsuser?.secondaryPhoneCountryCode),
    companyName: params.get("companyName"),
    query: params.get("query"),
    message: params.has("message")
      ? decodeURIComponent(params.get("message"))
      : "",
    slackChannel: params.has("channel") ? "#" + params.get("channel") : "",
  };

  const [show, setShow] = React.useState(false);
  const [phone, setPhone] = React.useState({
    cc: user?.countryCode || user?.secondaryPhoneCountryCode,
    phone: user?.phoneNumber || user?.secondaryPhone || "",
  });
  const [err, setErr] = React.useState("");
  const { country } = useSelector((state) => state.globals);

  const formRef = React.useRef({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    query: "",
    message: "",
  });

  React.useEffect(() => {
    if (user?.countryCode) return;

    if (country) setPhone({ ...phone, cc: country.dial_code });
  }, [country]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.phone.length < 6 || phone.phone.length > 14) {
      setErr("Please enter a valid phone number");
      return;
    } else {
      setErr("");
    }

    const paramsCopy = new URLSearchParams(params.toString());

    paramsCopy.delete("name");
    paramsCopy.delete("lastName");
    paramsCopy.delete("email");
    paramsCopy.delete("phoneNumber");
    paramsCopy.delete("countryCode");
    paramsCopy.delete("companyName");
    paramsCopy.delete("query");
    paramsCopy.delete("message");

    let utmParams = "";
    paramsCopy.forEach((value, key) => {
      utmParams += `\n${key}: ${value}`;
    });

    let msg = `*Name:* ${formRef.current.firstName.value} ${formRef.current.lastName.value}\n*Email:* ${formRef.current.email.value}\n*Company Name:* ${formRef.current.companyName.value}\n*Phone Number:* ${phone.cc} ${phone.phone}\n*Query type:* ${formRef.current.query.value}\n*Message:* ${formRef.current.message.value}`;

    if (utmParams) {
      msg += `\n\n*Tracking Parameters:* ${utmParams}`;
    }

    const channel =
      user.slackChannel ||
      (fromApp ? "#app-contact-form" : "#web-contact-form");

    dispatch({
      type: POST_CONTACTFORM,
      payload: {
        name: `${formRef.current.firstName.value} ${formRef.current.lastName.value}`,
        email: formRef.current.email.value,
        companyName: formRef.current.companyName.value,
        phoneNumber: `${phone.cc} ${phone.phone}`,
        queryType: formRef.current.query.value,
        message: formRef.current.message.value,
        whitelabelUserId: ENV.WL_USERID,
      },
    });

    sendSlackMessage(msg, channel);

    formRef.current.firstName.value = "";
    formRef.current.lastName.value = "";
    formRef.current.email.value = "";
    formRef.current.companyName.value = "";
    formRef.current.query.value = "";
    formRef.current.message.value = "";
    setPhone({ cc: "+44", phone: "" });
    setShow(true);
  };

  return (
    <>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton className="border-0">
          <Modal.Title
            style={{
              fontSize: "20px",
            }}
          >
            Thank you for contacting us!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-2">
          Our team member will get in touch with you shortly.
        </Modal.Body>
      </Modal>
      <Form onSubmit={handleSubmit}>
        <div className="contactus-header mb-4">Write to us</div>
        <Row className="g-2 align-items-center">
          <Col lg="6" md="12" sm="12">
            <input
              style={{ marginRight: "10px", width: "100%" }}
              type="text"
              placeholder="First Name"
              onChange={(e) => {
                formRef.current.firstName.value = e.target.value;
              }}
              defaultValue={user?.name}
              ref={(ref) => (formRef.current.firstName = ref)}
              required
              minLength={3}
            />
          </Col>
          <Col lg="6" md="12" sm="12">
            <input
              style={{ marginRight: "10px", width: "100%" }}
              type="text"
              placeholder="Last Name"
              onChange={(e) => {
                formRef.current.lastName.value = e.target.value;
              }}
              defaultValue={user?.lastName}
              ref={(ref) => (formRef.current.lastName = ref)}
              required
              minLength={3}
            />
          </Col>
          <Col lg="6" md="12" sm="12">
            <input
              style={{ marginRight: "10px", width: "100%" }}
              type="email"
              placeholder="Email Id"
              onChange={(e) => {
                formRef.current.email.value = e.target.value;
              }}
              defaultValue={user?.email}
              ref={(ref) => (formRef.current.email = ref)}
              required
              minLength={8}
            />
          </Col>
          <Col lg="6" md="12" sm="12">
            <input
              style={{ marginRight: "10px", width: "100%" }}
              type="text"
              placeholder="Company Name (optional)"
              onChange={(e) => {
                formRef.current.companyName.value = e.target.value;
              }}
              defaultValue={user?.companyName}
              ref={(ref) => (formRef.current.companyName = ref)}
              minLength={3}
            />
          </Col>
          <Col lg="6" md="12" sm="12">
            <MobileNoInput
              name={"loginCredential"}
              type="number"
              placeholder={"Phone Number"}
              val={phone.phone}
              countryCode={phone.cc || user?.countryCode}
              classes="justify-content-start align-items-center contact-us-page"
              onChange={(e) => {
                setPhone({ ...phone, phone: e.target.value });
              }}
              setCountryCode={(e) => {
                setPhone({ ...phone, cc: e });
              }}
              allowOnlyPhone
            />
          </Col>
          <Col lg="6" md="12" sm="12">
            <Form.Select
              onChange={(e) => {
                formRef.current.query.value = e.target.value;
              }}
              ref={(ref) => (formRef.current.query = ref)}
              defaultValue={user?.query || ""}
              required
            >
              <option value="" disabled>
                Select query type
              </option>
              <option value="General enquiry">General enquiry</option>
              <option value="Technical support">Technical support</option>
              <option value="Sales and pricing">Sales and pricing</option>
            </Form.Select>
          </Col>
          {err && (
            <Col lg="12" md="12" sm="12">
              <p className="input_fields-error">{err}</p>
            </Col>
          )}
          <Col lg="12" md="12" sm="12">
            <textarea
              className="col-md-12"
              name=""
              id=""
              cols="20"
              rows="8"
              placeholder="How can we help you?"
              onChange={(e) => {
                formRef.current.message.value = e.target.value;
              }}
              ref={(ref) => (formRef.current.message = ref)}
              minLength={25}
              maxLength={500}
              defaultValue={user?.message}
              required
            ></textarea>
          </Col>
        </Row>
        <Row className="g-2">
          <Col lg="12" md="12" sm="12" className="mb-1 contact-form-gray-text">
            By clicking on Submit, I agree to {ENV.WL_BUSINESS_NAME}&apos;s{" "}
            <br />
            <u>
              <a
                href={ENV.WL_PP_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </u>
          </Col>
          <Col lg="4" md="4">
            <DefaultButton title="Submit" className="font-bold" />
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContactusForm;
